<template>
	<div>
		<div class="common-header">
			<div class="search-healder" @click="index()">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
			</div>
		</div>
		<div class="lg-content">
			<div class="banner">
				<el-carousel height="400px" style="cursor: pointer;">
					<el-carousel-item v-for="(item,index) in banners" :key="index">
						<img class="banner-list" :src="item.banner" />
					</el-carousel-item>
				</el-carousel>
				<div class="login-tool">
					<div class="title">{{title}}登陆</div>
					<el-form ref="loginForm" :rules="rules" :model="loginForm">
						<el-form-item prop="phone">
							<el-input :autofocus="true" placeholder="手机号" prefix-icon="el-icon-phone"
								v-model="loginForm.phone">
							</el-input>
						</el-form-item>
						<el-form-item prop="code">
							<el-input placeholder="请输入验证码" prefix-icon="el-icon-lock" v-model="loginForm.code">
								<template slot="append" v-if="num>0">{{num+'秒后可重发'}}</template>
								<el-button slot="append" @click="send()" v-if="num==0">发送验证码</el-button>
							</el-input>
						</el-form-item>
					</el-form>
					<div class="login-tip">未注册手机验证后自动登录,注册即代表同意
						<div><span @click="service(0)">《隐私政策》</span><span @click="service(1)">《用户协议》</span></div>
					</div>
					<div class="err-tip" v-if="errTip">{{errTip}}</div>
					<el-button type="primary" class="login-btn" @click="toLogin('loginForm')"
						:loading="loading">登陆</el-button>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		setCookie,
		getCookie,
		delCookie
	} from '@/utils/auth'
	import {
		login,
		sendCode
	} from '@/api/auth/auth'
	import {
		bannerList
	} from '@/api/index/index'
	export default {
		data() {
			return {
				banners: [],
				loginForm: {
					account: '',
					password: '',
					isRemeber: false
				},
				rules: {
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}]
				},
				errTip: "",
				loading: false,
				num: 0,
				title: '会员'
			};
		},
		created() {
			this.title = getCookie('loginType') ? getCookie('loginType') : '会员'
			this.getBanner()
		},
		methods: {
			index() {
				// 编程式导航跳转
				this.$router.push({
					path: "/"
				})
			},
			service(type) {
				if (type == 0) { //隐私政策
					var routeData = this.$router.resolve({
						path: '/service'
					})
				} else if (type == 1) { //用户协议
					var routeData = this.$router.resolve({
						path: '/userAgreement'
					})
				}
				window.open(routeData.href, "_blank");
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			getBanner() {
				bannerList({}).then(res => {
					this.banners = res.data
				})
			},
			send() {
				var that = this
				if (!this.loginForm.phone) {
					this.errTip = "请输入手机号"
					return
				} else {
					this.errTip = ''
				}
				sendCode({
					phone: this.loginForm.phone
				}).then(res => {
					if (res.code != 200) {
						this.errTip = res.msg
					} else {
						that.num = 60
						var login = setInterval(function() {
							that.num = that.num - 1
							if (that.num <= 0) {
								clearInterval(login)
							}
						}, 1000)
					}
				})
			},
			toLogin(form) {
				this.errTip = ''
				if (this.loading) {
					return
				}
				this.loading = true
				this.$refs[form].validate((valid) => {
					if (valid) {
						login(this.loginForm).then(res => {
							setCookie("userInfo", JSON.stringify(res.data))
							setCookie("token", res.data.token)
							delCookie('loginType')
							this.toUrl('/')
						})
						this.loading = false
					} else {
						this.loading = false
					}
				});
			}
		},
	};
</script>

<style lang='scss'>
	.el-input-group__append {
		cursor: pointer;
	}

	.lg-content {
		min-width: 1200px;

		.banner {
			width: 100%;
			height: 400px;
			background: #999;

			.banner-list {
				width: 100%;
				height: 400px;
			}

			.login-tool {
				height: 380px;
				background-color: #fff;
				width: 350px;
				position: absolute;
				margin-top: -390px;
				z-index: 99;
				right: 20%;
				border-radius: 20px;

				.login-tip {
					line-height: 20px;
					font-size: 12px;
					text-align: center;
					color: #909399;

					span {
						cursor: pointer;
						color: #4e6ef2;
						font-weight: bold;
					}
				}

				.title {
					width: 100%;
					text-align: center;
					line-height: 30px;
					font-size: 18px;
					margin-bottom: 20px;
					font-weight: bold;
					padding-top: 20px;
				}

				.el-input {
					width: 80%;
					margin-left: 10%;

					.el-input__inner {
						border: 1px solid #D9001B;
					}
				}

				.el-form-item__error {
					padding-left: 10%;
				}

				.err-tip {
					width: 80%;
					margin-left: 10%;
					font-size: 12px;
					color: #F56C6C;
				}


				.login-btn {
					background-color: #D9001B;
					border: none;
					width: 150px;
					margin-left: 75px;
					margin-top: 20px;
				}

				.login-btn:hover {
					opacity: 0.7;
					-webkit-animation-delay: 0.5s;
				}
			}
		}
	}
</style>