import request from '@/utils/request'

export function login(params) {
	return request({
		url: '/auth/login',
		method: 'post',
		data: params
	})
}

export function sendCode(params) {
	return request({
		url: '/auth/send/code',
		method: 'post',
		params
	})
}